import { Button, Flex, Table, Tooltip } from "@radix-ui/themes";
import {
  IconAlertTriangle,
  IconChevronLeft,
  IconChevronRight,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
interface PostsListViewProps {
  fullPosts: Post[];
  extraControls?: (post: Post) => React.ReactNode;
}
const PostsListView = ({ fullPosts, extraControls }: PostsListViewProps) => {
  const [page, setPage] = useState(0);
  const PAGE_SIZE = 10;
  const headers = [
    "Post",
    "Link",
    "Author",
    "Post Date",
    "Likes",
    "Shares",
    "Views",
    "Comments",
  ];
  const [posts, setPosts] = useState([]);
  const [sortKey, setSortKey] = useState("Views");
  const [sortDir, setSortDir] = useState(1);

  const headerToKey = {
    "Post Date": "postedAt",
    Author: "username",
    Views: "views",
    Likes: "likes",
    Shares: "shares",
    Comments: "comments",
  };

  useEffect(() => {
    setPage(0);
    setPosts(
      [...fullPosts].sort((a: any, b: any) => {
        const key = headerToKey[sortKey];
        if (key === "postedAt") {
          return (
            (new Date(b[key]).getTime() - new Date(a[key]).getTime()) * sortDir
          );
        }
        if (key === "username") {
          if (sortDir === 1) {
            return b[key]?.localeCompare(a[key]);
          } else {
            return a[key]?.localeCompare(b[key]);
          }
        }
        return (b[key] - a[key]) * sortDir;
      })
    );
  }, [sortKey, sortDir]);
  console.log("posts", posts);

  return (
    <Flex direction="column" gap="4">
      <Table.Root size="1">
        <Table.Header>
          <Table.Row>
            {headers.map((item: any) => (
              <Table.ColumnHeaderCell
                style={{
                  cursor: headerToKey[item] === undefined ? "auto" : "pointer",
                }}
                onClick={() => {
                  if (headerToKey[item] === undefined) {
                    return;
                  }
                  if (sortKey === item) {
                    setSortDir((prev) => prev * -1);
                  } else {
                    setSortKey(item);
                    setSortDir(1);
                  }
                }}
                key={item}
              >
                <Flex direction="row" align="center" gap="1">
                  {item}
                  {item === sortKey &&
                    (sortDir === 1 ? (
                      <IconSortDescending
                        size="20px"
                        className="icon"
                        style={{
                          color:
                            item === sortKey
                              ? "var(--gray-10)"
                              : "var(--gray-7)",
                        }}
                      />
                    ) : (
                      <IconSortAscending
                        size="20px"
                        className="icon"
                        style={{
                          color:
                            item === sortKey
                              ? "var(--gray-10)"
                              : "var(--gray-7)",
                        }}
                      />
                    ))}
                </Flex>
              </Table.ColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {posts.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((post) => (
            <Table.Row align="center" key={post.id}>
              <Table.Cell style={{ width: "10%" }}>
                <Flex direction="row" align="center" gap={"1"}>
                  <Link href={post.url} target="_blank">
                    <div
                      className="small-thumbnail"
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      {post.thumbnailSmall ? (
                        <Image
                          fill
                          className="small-thumbnail"
                          src={`${process.env.NEXT_PUBLIC_DOMAIN}/image-upload/${post.thumbnailSmall}`}
                          alt=""
                        />
                      ) : post.thumbnail ? (
                        <Image
                          fill
                          className="small-thumbnail"
                          src={`${process.env.NEXT_PUBLIC_DOMAIN}/image-upload/${post.thumbnail}`}
                          alt=""
                        />
                      ) : (
                        <Image
                          fill
                          className="small-thumbnail"
                          src="/PostImage.png"
                          alt=""
                        />
                      )}
                    </div>
                  </Link>
                  {post?.missing && (
                    <Tooltip content={"Post is missing!"}>
                      <div>
                        <IconAlertTriangle className="icon-accent" size="20" />
                      </div>
                    </Tooltip>
                  )}
                </Flex>
              </Table.Cell>
              <Table.Cell style={{ width: "20%" }}>
                <Link href={post.url} target="_blank">
                  {post.url}
                </Link>
              </Table.Cell>
              <Table.Cell style={{ width: "15%" }}>@{post.username}</Table.Cell>
              <Table.Cell style={{ width: "14%" }}>
                {new Date(post.postedAt)?.toLocaleDateString()}
              </Table.Cell>
              <Table.Cell style={{ width: "14%" }}>
                {post.shares && post.views && !post.likes
                  ? "Hidden"
                  : post.likes
                  ? post.likes.toLocaleString()
                  : ""}
              </Table.Cell>
              <Table.Cell style={{ width: "14%" }}>
                {post.shares?.toLocaleString()}
              </Table.Cell>
              <Table.Cell style={{ width: "14%" }}>
                {post.views?.toLocaleString()}
              </Table.Cell>
              <Table.Cell style={{ width: "14%" }}>
                {post.comments?.toLocaleString()}
              </Table.Cell>
              {extraControls && (
                <Table.Cell>
                  <Flex direction="row" gap="2" align="center">
                    {extraControls(post)}
                  </Flex>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
      <Flex direction="row" gap="3" justify="center">
        <Button
          variant="ghost"
          disabled={page === 0}
          onClick={() => setPage((prev: any) => prev - 1)}
        >
          <IconChevronLeft className="icon-accent" />
          Previous
        </Button>
        <Button
          variant="ghost"
          disabled={page >= posts?.length / PAGE_SIZE - 1}
          onClick={() => setPage((prev: any) => prev + 1)}
        >
          Next
          <IconChevronRight className="icon-accent" />
        </Button>
      </Flex>
    </Flex>
  );
};

export default PostsListView;
